body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Quicksand, Arial, sans-serif;
}

[class^="icon-"],
[class*=" icon-"] {
  font-size: 125%;
  position: relative;
  top: 2px;
}

#colorlib-page {
  background-color: rgb(242 243 247);
}

#colorlib-main {
  background-color: rgb(255 255 255);
}

@media screen and (width <= 768px) {
  #colorlib-main {
    padding: 0;
  }

  .colorlib-narrow-content {
    padding: 0 1em;
  }
}

.colorlib-heading {
  margin-top: 0.5em;
  margin-bottom: 1em;
}

@media screen and (width <= 768px) {
  .colorlib-heading {
    margin-top: 0.5em;
    margin-bottom: 1em;
  }
}

section[class^="colorlib-"] {
  padding-top: 0.5em;
  padding-bottom: 2em;
}

@media print {
  #colorlib-main {
    width: 100%;
  }

  .animate-box {
    opacity: 1 !important;
  }

  .row-pt-md {
    margin-top: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 2em !important;
  }

  .services {
    border-bottom-style: unset !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .services .icon {
    display: none !important;
  }
}
