.colorlib-nav-toggle {
  left: 0;
  top: -5px;
}

/* pseudo-element for nav toggle background */
.colorlib-nav-toggle::before {
  content: "";
  position: absolute;
  top: 15px;
  left: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(242 243 247 / 60%);
  z-index: -1;
}

#colorlib-aside {
  padding-top: 1em;
}

/* sidebar logo */
#colorlib-aside .author-img {
  background-size: 100px;
  margin-top: 15px;
  margin-bottom: 15px;
}

/* sidebar heading (below logo) */
#colorlib-aside #colorlib-logo {
  font-size: 30px;
  text-align: center;
}

/* sidebar phone number */
#colorlib-aside .position {
  font-size: 14px;
}

#colorlib-aside .colorlib-footer {
  padding-top: 3em;
}
