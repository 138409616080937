#colorlib-hero {
  background: unset;
  margin-bottom: 2em;
}

#colorlib-hero .flexslider .slider-text {
  z-index: 1001;
}

#colorlib-hero .flexslider .slider-text > .slider-text-inner {
  padding-left: 5em;
  padding-right: 5em;
}

@media screen and (width <= 768px) {
  #colorlib-hero .flexslider .slider-text > .slider-text-inner {
    padding-left: 2.5em;
    padding-right: 2.5em;
  }
}

#colorlib-hero .flexslider .slider-text > .slider-text-inner h1 {
  color: rgb(255 255 255 / 95%);
  font-family: Quicksand, Arial, sans-serif;
  font-size: 60px;
  line-height: 1.1;
  text-shadow: 0 0 10px rgb(0 0 0 / 80%);
}

@media screen and (width <= 768px) {
  #colorlib-hero .flexslider .slider-text > .slider-text-inner h1 {
    font-size: 45px;
  }
}

#colorlib-hero .flexslider .slider-text > .slider-text-inner .heading-section {
  font-size: 70%;
  font-weight: 500;
}

#colorlib-hero .flexslider .slider-text > .slider-text-inner .btn.btn-learn {
  background: rgb(0 0 0 / 20%) !important;
  border-color: rgb(255 255 255) !important;
  color: rgb(255 255 255);
  font-size: 16px;
  font-weight: 500;
}

@media screen and (width <= 768px) {
  #colorlib-hero .flexslider .slider-text > .slider-text-inner .btn.btn-learn {
    width: auto;
  }
}

#colorlib-hero .flexslider .slider-text > .slider-text-inner .btn.btn-learn:hover {
  background: rgb(0 0 0 / 50%) !important;
  border-color: rgb(255 255 255) !important;
}
